import React from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import { EMBEDDABLES_START_NOW_FLOW_ID } from "../layouts/scripts";

export default (data: any) => {
  const urlParams = new URLSearchParams(data.location.search);
  const useEmbeddables = urlParams.get("useEmbeddables");

  const runEmbeddablesScript = () => {
    if (useEmbeddables === "true") {
      return true;
    }

    if (useEmbeddables === "false") {
      return false;
    }

    return true;
  };

  return (
    <BasicLayout
      runEmbeddedablesScript={runEmbeddablesScript()}
      embeddedablesFlowId={EMBEDDABLES_START_NOW_FLOW_ID}
    >
      <>
        <div />
      </>
    </BasicLayout>
  );
};
